export default {
  text: "#ccf",
  background: "#003",
  headerBackground: '#005',
  primary: "#003",
  secondary: "#444",
  headerText: "#333",
  sidebarNavText: "#ccf",
  sidebarTOC    : "#004",
  sidebar       : "#004",
  sidebarHover  : "#349",
  sidebarActive : "#006",
  borderColor: "rgba(255, 255, 255, 0.15)",
  modes: {
    dark: {
      text: "#fff",
      background: "#182952",
      primary: "#f638dc",
      secondary: "#ff7976",
      sidebar: "#101d3c",
      borderColor: "rgba(255, 255, 255, 0.15)"
    },
    cool: {
      text: "#fff",
      background: "#05386b",
      primary: "#5cdb95",
      secondary: "#bef992",
      sidebar: "#052e56",
      borderColor: "rgba(255, 255, 255, 0.15)"
    },
    deep: {
      text: "#fff",
      background: "hsl(230,25%,18%)",
      primary: "hsl(260, 100%, 80%)",
      secondary: "hsl(290, 100%, 80%)",
      sidebar: "hsla(230, 20%, 0%, 20%)",
      borderColor: "rgba(255, 255, 255, 0.15)"
    }
  }
};
